footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  background-color: #d8d8d8;
  border-top: 1px solid #b8b8b8;

  .section {
    align-items: normal;
  }
}

.button--contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c01a1c;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  width: 164px;
  padding: 6px 16px;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgb(154, 0, 54);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .material-icons {
    margin-left: 10px;
  }
}

.button--navigate {
  background: none;
  border: none;
  padding: 8px;
  color: black;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-animation: bounce 0.6s infinite alternate;
  -moz-animation: bounce 0.6s infinite alternate;
  animation: bounce 0.6s infinite alternate;

  .material-icons {
    font-size: 40px;
  }
}

@-webkit-keyframes bounce {
  to {
    -webkit-transform: translateY(10px);
  }
}
@-moz-keyframes bounce {
  to {
    -moz-transform: translateY(10px);
  }
}
@keyframes bounce {
  to {
    transform: translateY(10px);
  }
}
