.navigation {
  position: fixed;
  display: flex;
  flex-direction: row;
  background: rgba(51, 51, 51, 0.8);
  padding-left: calc(10% - 16px);
  width: 100%;
  box-sizing: border-box;
  top: 0;
  transition: top 0.2s ease-in-out;
  z-index: 5;

  &--up {
    top: -50px;
  }
}

.navigation__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .navigation__list {
    display: none;
  }
}

.navigation__item {
  float: left;
}

.navigation__item-anchor,
.navigation__logo {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navigation__item-anchor:hover {
  background: rgba(30, 30, 30, 0.6);
}

.navigation__logo {
  font-weight: 700;

  &::first-letter {
    color: #c01a1c;
  }
}
