html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 48px;
  font-weight: 400;
}

h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
  color: #222222;
}

p {
  font-size: 14px;
  line-height: 2;
  color: #545454;
}

ul {
  font-size: 14px;
  line-height: 1.6;
  color: #545454;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
