.product {
  margin-bottom: 40px;
  opacity: 0;

  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;

  &.fade-in {
    opacity: 1;
  }

  &.product--2 {
    transition-delay: 0.5s;

    .product__half--top {
      transition-delay: 0.5s;
    }
  }
}

.product__half {
  .product__contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    height: 110px;
    padding: 20px 10px;

    span {
      text-align: center;
      height: 20px;
      font-size: 13px;
      font-weight: 600;
      margin-left: 20px;
    }
  }

  &--d8d8d8 {
    .product__contents {
      background-color: #d8d8d8;
    }
  }

  &--888888 {
    .product__contents {
      background-color: #888888;

      span {
        color: white;
      }
    }
  }

  svg {
    overflow: hidden;
  }
}

.product__half--top {
  transform: translateY(-120px);

  -webkit-transition: transform 1s ease-out;
  -moz-transition: transform 1s ease-out;
  -ms-transition: transform 1s ease-out;
  -o-transition: transform 1s ease-out;
  transition: transform 1s ease-out;

  svg {
    transform: scaleY(-1);
  }

  &.slide-down {
    transform: translateY(32px);
  }
}

.product__half--bottom {
  svg {
    margin-bottom: -5px;
  }
}
