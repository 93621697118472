.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5% 10%;
  width: 100%;
  box-sizing: border-box;

  .section__image {
    width: 42vw;
    height: 100%;
  }

  .section__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 32vw;

    ul {
      padding-inline-start: 20px;
    }
  }

  &--centered {
    align-items: center;

    .section__text {
      text-align: center;
      width: 40rem;
      max-width: 100%;
    }
  }

  &--row {
    flex-direction: row;
    align-items: center;
  }

  &--row-reverse {
    flex-direction: row-reverse;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    &--row,
    &--row-reverse {
      flex-direction: column;

      .section__text,
      .section__image {
        width: 100%;
      }
    }
  }
}

.section__columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .column {
    width: 32vw;
  }
}

.section__grid {
  display: grid;
  margin-top: 40px;
  width: 100%;
  grid-gap: 5rem;
  grid-template-columns: repeat(4, minmax(16vw, auto));
  grid-template-rows: repeat(3, auto);
}

@media only screen and (max-width: 1024px) {
  .section__grid {
    grid-template-columns: repeat(3, minmax(20vw, auto));
    grid-template-rows: repeat(4, auto);
  }
}

@media only screen and (max-width: 768px) {
  .section__columns {
    flex-direction: column;

    .column {
      width: 100%;
    }

    .column:not(:last-child) {
      margin-bottom: 80px;
    }
  }

  .section__grid {
    grid-template-columns: repeat(2, minmax(30vw, auto));
    grid-template-rows: repeat(6, auto);
  }
}
