.landing {
  height: 600px;
  background-image: url("./assets/bg.jpg");
  background-size: cover;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.landing__content {
  width: 38%;
  text-align: left;
}

@media only screen and (max-width: 1180px) {
  .landing__content {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .landing__content {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .landing__header {
    font-size: 36px;
  }
}

.landing__header {
  color: white;
  margin-bottom: 10px;
}

.landing__description {
  font-size: 16px;
  color: white;
}

.image-placeholder {
  background-color: #333;
  width: 42vw;
  height: 100%;
}
